import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import smoothscroll from 'smoothscroll-polyfill';
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";
import ContactSection from "../components/ContactSection";
import CustomLink from "../components/CustomLink";
import Tabs from "../components/tabs/Tabs";

import BookIcon from "../components/svg/book-icon";



class Hero extends React.Component {

  scrollDownToValues() {
    window.scrollTo({
      top: 800,
      left: 0,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    smoothscroll.polyfill();
  }

  render() {
    const section = this.props.hero;

    return (
      <div className="hero-wrapper flex-column justc-center alitems-center">
        <div className="content-wrapper flex-row justc-space-b alitems-center flex-1
                                        m-flex-column-reverse m-justc-center m-alitems-center">
          <div className="hero-content-text">
            <h1 className="title-2 bold-weight white-text">{section.title}</h1>
            <h2 className="title-5 text-xlight mgt-s">{section.subtitle}</h2>
          </div>
          <div className="hero-content-image">
            <img src="/img/hero-image.svg" alt="Laptop Book illustration"/>
          </div>
        </div>
        {/* <button onClick={this.scrollDownToValues} className="hero-arrow">
          <img src="/img/icons/arrow-down.svg" alt="arrow down"/>
        </button> */}
        <div className="ng-login-card flex-column alitems-center mgt-l">
          <div className="ng-login-text">
            <h3 className="title-4 section-title mgb-xs">
              {section.ngLogin.title}
            </h3>
            {section.ngLogin.description}
          </div>
          <div className="ng-login-ui flex-row justc-space-a s-flex-column">
            <div className="v2login flex-column alitems-center">
              <img className="mgb-xs" src="/img/v2-illu.svg" alt="Electre v2 ui representation" />
              <a className="cta yellow fill small mgt-xs" href="https://www.electre.com/ElectreLogin.aspx">{section.ngLogin.v2title}</a>
            </div>
            <div className="nglogin flex-column alitems-center">
              <img className="mgb-xs" src="/img/ng-illu.svg" alt="Electre v2 ui representation" />
              <a className="cta blue fill small mgt-xs" href="https://www.electre-ng.com/">{section.ngLogin.ngtitle}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};




class Personae extends React.Component {
  render() {
    const section = this.props.personae;
    
    return (
      <div className="section">
        <div className="content-wrapper personae">
          <h3 className="title-3 section-title left">
            {section.title}
          </h3>

          <Tabs>
            {section.personae.map( (persona, index) => (
              <div key={index} label={persona.name}>
                <div className="persona-text-content">
                  <div className="title-4 bold-weight text-light persona-title">
                    <ReactMarkdown children={persona.title}/>
                  </div>
                  <div className="flex-1 flex-column justc-space-b alitems-start">
                    <div className="feature-list-container">
                      {persona.features.map( (feature, index) => (
                        <div key={index} className="persona-feature">
                          <BookIcon/>
                          <ReactMarkdown children={feature.content} className="flex-1"/>
                        </div>
                      ))}
                    </div>
                    <Link className="cta blue fill" 
                          to="/contact/"
                          state={{ contactType: "commercial-selected" }}>
                      Nous contacter
                    </Link>
                  </div>
                </div>
                <div className="persona-image"
                     style={{backgroundImage: 'url(' + persona.image + ')'}}>
                </div>
              </div>
            ))}
          </Tabs>

        </div>
      </div>
    )
  };
}




class Features extends React.Component {
  render() {
    const section = this.props.features;

    return (
      <div className="section gradient-section">
        <div className="content-wrapper">
          <h3 className="title-3 section-title center white-text">{section.title}</h3>
          <div className="flex-row justc-space-b alitems-stretch
                          m-flex-column m-justc-start m-alitems-center">
            {section.features.map( (feature, index) => (
              <div key={index} className="card with-image one-third-width dark
                                                          m-full-width m-mgb-m">
                <div className="card-image mgb-s"
                     style={{backgroundImage: 'url(' + feature.image + ')'}}>
                </div>
                <div className="feature-text">
                  <div className="title-5 medium-weight mgb-xs">{feature.title}</div>
                  {feature.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}



class ServicesHome extends React.Component {
  render() {
    const content = this.props.services;

    return (
      <div className="section services-home-section">
        <div className="content-wrapper flex-row justc-space-b alitems-stretch
                                        m-flex-column-reverse m-alitems-center">
          <div className="services-home-illu flex-row alitems-center">
            <img src="/img/services-home-illu.svg" alt="Services"/>
          </div>
          <div className="services-home-text flex-column justc-center m-justc-start">
            <h3 className="title-3 section-title left">{content.title}</h3>
            <div className="arrow-list">
              <ul>
                {content.list.map( (service, index) => (
                  <li key={index}>
                    {service}
                  </li>
                ))}
              </ul>
              <CustomLink 
                linkType="internal"
                linkURL="/services"
                className="cta fill blue mgt-m">
                Plus d'informations
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



class Numbers extends React.Component {
  render() {
    const section = this.props.numbers;

    return (
      <div className="section large gradient-section overflow-hidden">
        <div className="content-wrapper flex-row justc-space-b
                                        m-flex-column m-alitems-center">
          {section.map( (numberitem, index) => (
            <div key={index} className="number-item m-mgb-m">
              <div className="stroke-number bold-weight">
                {numberitem.number}
              </div>
              <div className="number-label white-text title-5">
                {numberitem.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}


class Clients extends React.Component {
  render() {
    const section = this.props.clients;

    return (
      <div className="section">
        <div className="content-wrapper">
          <h3 className="title-3 section-title center">{section.title}</h3>
          <div className="client-logo-wrapper flex-row justc-center wrap">
            {section.clients.map( (client,index) => (
              <img key={index} className="client-logo" src={client.image} alt={client.name} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}


// Using a template that includes all the other component without the layout
// so we can use it as a preview in the CMS
export const HomePageTemplate = ({ hero, personae, features, services, numbers, clients }) => {
  return (
    <div>
      <Hero hero={hero}/>
      <Personae personae={personae}/>
      <Features features={features}/>
      <ServicesHome services={services}/>
      <Numbers numbers={numbers}/>
      <Clients clients={clients}/>
      <ContactSection/>
    </div>
  )
}

class HomePage extends React.Component {

  render() {

    const { data } = this.props;
    const {
      data: { footerData, navbarData },
    } = this.props;
    const { frontmatter: home } = data.homePageData.edges[0].node;
    const {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    } = home;

    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <Helmet>
          <meta name="title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <title>{browserTitle}</title>
        </Helmet>

        <HomePageTemplate 
          hero={home.hero}
          personae={home.personae}
          features={home.features}
          services={home.services}
          numbers={home.numbers}
          clients={home.clients}/>
      </Layout>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    ...LayoutFragment
    homePageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "home-page" } } }) {
      edges {
        node {
          frontmatter {
            title
            hero {
              title
              subtitle
              ngLogin {
                title
                description
                v2title
                ngtitle
              }
            }
            personae {
              title
              personae {
                name
                title
                image
                features {
                  content
                }
              }
            }
            features {
              title
              features {
                image
                title
                content
              }
            }
            services {
              title
              list
            }
            numbers {
              number
              label
            }
            clients {
              title
              clients {
                name
                image
              }
            }
            description
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`;
