import React from "react";

const SvgBookList = props => (
  <svg viewBox="0 0 50 50" {...props}>
    <circle cx={22.2} cy={22.4} r={19.8} fill="#ffc568"/>
    <path
      fill="#546572"
      d="M25.9 48.5l-7.2-2.9-.6-.9V14.9l1-1h.1l.1-.1.3-.4L37 1.8l1.5.8v.8l3.3 1.7-.9 1.8-3.9-2-.3-.6L21 14.9l-.3.5-.6.3V44l6 2.4.1-.1.9-.3.3.1 17-12V7.7L27.3 19l-.9.1-4.6-1.9.8-1.8 4.1 1.7L44.8 5l1.6.8v28.8l-.4.8L28.2 48l-.8.2-.2-.1-.2.2z"
    />
  </svg>
);

export default SvgBookList;